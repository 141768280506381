import { FC } from 'react'
import bdlLogo from 'assets/images/bdl-logo.svg'

export interface FooterProps {
  [x: string]: any
}

export const Footer: FC<FooterProps> = ({ ...restProps }: FooterProps) => {
  return (
    <div
      className='main-layout__Footer absolute bottom-0 mb-4 mx-8 flex justify-between items-center w-7/12 px-8'
      {...restProps}>
      {/* footer year */}
      <div className='flex flex-col items-center text-small'>
        <p className='items-center text-N-500'>
          © {new Date().getFullYear()} B dynamic Logistics Pty Ltd. All rights reserved.
        </p>

        {/* <div className='flex items-center w-full mt-2'>
          <a className='text-S-900 pr-2' href='www.google.lk'>
            Terms and conditions
          </a>
          <a className='text-S-900 pl-2' href='www.google.lk'>
            Help?
          </a>
        </div> */}
      </div>

      <div className='text-small text-N-500 flex items-center'>
        Powered By
        <img src={bdlLogo} alt='' className='object-contain h-[25.5px] ml-1' />
      </div>
    </div>
  )
}

Footer.defaultProps = {}

export default Footer
