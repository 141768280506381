import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CN from 'classnames'
import { useGetPartnerOrderDetailsById } from 'framework/api/methods'
import {
  CLOSED,
  CONFIRMED,
  OPEN,
  PARTIALCONSIGNED,
  RECEIVED,
  TOBECONSIGNED,
} from 'static-data/orderStatus'
import { setBackFunction } from 'store/reducers/layout/layoutSlice'

import { PageLoader } from 'components/atoms'
import { UITable } from 'components/common/UITable'
import { Toast } from 'components/molecules'

import { OrderInformationSection } from './OrderInformationSection'
import { OrderLineListColumn } from './OrderLineListColumn'
import { OrderStatusProgressBar } from './OrderStatusProgressBar'

export interface OrderDetailsProps {
  className?: string | undefined
  [x: string]: any
}

export const OrderDetails: FC<OrderDetailsProps> = ({
  ...restProps
}: OrderDetailsProps) => {
  const navigate = useNavigate()
  const dispatch: any = useDispatch()

  const [orderDetails, setOrderDetails] = useState<any>({})
  const [searchParams] = useSearchParams()
  const notify = (props: any) => Toast(props)

  const [paginationVariables, setPaginationVariables] = useState<any>({
    take: 100,
  })

  const [orderLineTableColumn, setOrderLineTableColumn] = useState<any>([])
  const [warehouseDetails, setWarehouseDetails] = useState<any>({})
  const [shippingInformation, setShippingInformation] = useState<any>({})

  useEffect(() => {
    callGetPartnerOrderDetailsById()
  }, [])

  const [tempStatus, setTempStatus] = useState<any>([])

  useEffect(() => {
    dispatch(
      setBackFunction(() => {
        navigate('/')
      }),
    )
  }, [])

  /** Get Partner Order Details By Id Success Function */
  const getPartnerOrderDetailsByIdOnSuccess = (data: any) => {
    const { partnerOrder } = data || {}
    const columns: any = OrderLineListColumn()
    setOrderLineTableColumn(columns)
    setOrderDetails(partnerOrder)
    let temp = ''
    switch (partnerOrder?.status) {
      case RECEIVED:
        temp = 'Received'
        break
      case CONFIRMED:
        temp = 'Confirmed'
        break
      case OPEN:
        temp = 'Picking-Progress'
        break
      case TOBECONSIGNED:
        temp = 'Picking-Completed'
        break
      case CLOSED:
        temp = 'Closed'
        break
      case PARTIALCONSIGNED:
        temp = 'Partial-consigned'
        break
    }
    setTempStatus(temp)
    setWarehouseDetails(data?.warehouse)
    setShippingInformation(data?.shipmentConsignment)
  }

  /** Get Partner Order Details By Id Error Function */
  const getPartnerOrderDetailsByIdOnError = (error: any) => {
    notify({
      alertHeader: 'Error',
      alertBody: error.message ? error.message : 'Something went wrong',
      status: 'Error',
    })
  }

  /** API Call for Get Partner Order Details By Id */
  const {
    isLoading: GetPartnerOrderDetailsByIdIsLoading,
    refetch: callGetPartnerOrderDetailsById,
  } = useGetPartnerOrderDetailsById(
    {
      orderId: searchParams.get('order_id') || '',
    },
    getPartnerOrderDetailsByIdOnSuccess,
    getPartnerOrderDetailsByIdOnError,
  )

  return (
    <div className='relative' {...restProps}>
      <div className='rounded-lg p-3 shadow-md bg-white'>
        {GetPartnerOrderDetailsByIdIsLoading && <PageLoader />}
        <OrderStatusProgressBar
          currentStatus={tempStatus || '-'}
          partnerOrderHistory={orderDetails?.partnerOrderHistory || []}
        />

        <OrderInformationSection
          orderDetails={orderDetails}
          warehouseDetails={warehouseDetails}
          shippingInformation={shippingInformation}
        />

        <div className='font-semibold text-heading-3 text-N-800 mt-8 pb-6'>
          Order Line
        </div>

        <div
          className={CN('w-full mb-2 relative h-[calc(80vh-350px)]', {
            '!h-auto':
              orderDetails?.partnerOrderLines &&
              orderDetails?.partnerOrderLines?.length > 0,
          })}>
          <div
            className={CN(
              'styled-scroll h-[calc(100%-8px)] overflow-auto w-full bg-white',
            )}>
            <UITable
              data={orderDetails?.partnerOrderLines || []}
              isLoading={GetPartnerOrderDetailsByIdIsLoading}
              className=''
              columns={orderLineTableColumn || []}
              allowRowSelection={false}
              isSorted={true}
              isPaginated={false}
              isManualSortBy={false}
              hasFooter={false}
              hasCheckBox={false}
              isHeader={true}
              isFilterEnabled={false}
              paginationVariables={paginationVariables}
              setPaginationVariables={setPaginationVariables}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

OrderDetails.defaultProps = {
  className: undefined,
}

export default OrderDetails
