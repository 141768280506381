import { useMutation } from 'react-query'
import { get, postAPI } from 'framework/api/http'

interface GetPartnerOrdersInterface {
  take?: number
  skip?: number
  search?: string
  status?: string
  integration?: string
  from?: string | null
  to?: string | null
  sortBy?: string
  sortDirection?: string
}

export const useGetPartnerOrders = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      search,
      status,
      integration,
      from,
      to,
      sortBy,
      sortDirection,
    }: GetPartnerOrdersInterface) => {
      return postAPI(`/PartnerOrders?`, {
        take,
        skip,
        searchKeyword: search === '' ? null : search,
        status: status === 'All' ? null : status,
        integration: integration === 'All' ? null : integration,
        partnerOrderId: null,
        from: from || null,
        to: to || null,
        sortBy: sortBy === '' ? null : sortBy,
        sortDirection: sortDirection === '' ? null : sortDirection,
      })
    },
  )

  return mutate
}

export default useGetPartnerOrders
