import {
  BACKORDER,
  CANCELLED,
  CLOSED,
  CONFIRMED,
  EXCEPTION,
  FAILED,
  ONHOLD,
  OPEN,
  PARTIALCONSIGNED,
  RECEIVED,
  TOBECONSIGNED,
} from 'static-data/orderStatus'

export const orderStatusNameConversion = (status: string | undefined) => {
  let orderStatus = ''

  switch (status) {
    case RECEIVED:
      orderStatus = 'Received'
      break
    case CONFIRMED:
      orderStatus = 'Confirmed'
      break
    case FAILED:
      orderStatus = 'Failed'
      break
    case EXCEPTION:
      orderStatus = 'Error'
      break
    case OPEN:
      orderStatus = 'Picking Progress'
      break
    case TOBECONSIGNED:
      orderStatus = 'Pick Completed'
      break
    case PARTIALCONSIGNED:
      orderStatus = 'Partial Consigned'
      break
    case ONHOLD:
      orderStatus = 'On Hold'
      break
    case CANCELLED:
      orderStatus = 'Cancelled'
      break
    case CLOSED:
      orderStatus = 'Closed'
      break
    case BACKORDER:
      orderStatus = 'Error'
  }

  return orderStatus
}

export default orderStatusNameConversion
