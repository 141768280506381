import {FC, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import CN from 'classnames'
import {useLogin} from 'framework/api/methods'
import {setLogoutModalHide} from 'store/reducers/layout/layoutSlice'
import {setToInitial, setUserDetails} from 'store/reducers/user/userSlice'

import {Button, Checkbox, InputField} from 'components/atoms'
import {Toast} from 'components/molecules'

export interface LoginFormProps {
    [x: string]: any
}

export const LoginFrom: FC<LoginFormProps> = ({
                                                  ...restProps
                                              }: LoginFormProps) => {
    const navigate = useNavigate()
    const dispatch: any = useDispatch()
    const notify = (props: any) => Toast(props)

    const [userFormDetails, setUserFormDetails] = useState({
        username: '',
        password: '',
    })

    // const [rememberMeStatus, setRememberMeStatus] = useState(true)

    /** Get Local Token */
    const LocalStorageToken = localStorage.getItem('token')
    const sessionStorageToken = sessionStorage.getItem('token')
    const [showPassword, setShowPassword] = useState(false)
    const token = LocalStorageToken || sessionStorageToken

    /** Redirect user to Homepage if Token exists */
    useEffect(() => {
        if (token) {
            navigate('/', {replace: true})
        }
        dispatch(setLogoutModalHide())
    })

    /** Log In User API Call ------> Mutate */
    const {mutate: logInMutate, isLoading: logInIsLoading} = useLogin()

    /** Process the Login submission */
    async function logIn(userData: any) {
        logInMutate(userData, {
            onSuccess: ({data: successData}: any) => {
                localStorage.setItem('token', successData.token)
                // if (rememberMeStatus) {
                //     localStorage.setItem('refreshToken', successData.refreshToken)
                // }

                dispatch(setUserDetails(successData.clientProfile))
                navigate('/', {replace: true})
            },
            onError: ({response: {data: errData}}: any) => {
                notify({
                    alertHeader: 'Error',
                    alertBody: errData.title ? errData.title : 'Something went wrong',
                    status: 'Error',
                })
                dispatch(setToInitial())
            },
        })
    }

    /** handle login button click */
    async function handleLogInClick() {
        logIn(userFormDetails)
    }

    // const rememberMeAction = (status: boolean) => {
    //     setRememberMeStatus(status)
    // }

    return (
        <div className='flex flex-col justify-center' {...restProps}>
      <span className='flex justify-center items-center font-bold text-heading-1 text-N-700 pt-[60px]'>
        Welcome
      </span>

            <span className='flex justify-center items-center font-medium text-base text-N-700 pt-2'>
        Please login to your account to get started.
      </span>

            <InputField
                type='text'
                label='Email'
                placeholder=''
                className='pt-8 w-full'
                isErrored={false}
                onChange={(e: any) => {
                    setUserFormDetails({
                        ...userFormDetails,
                        username: e.target.value,
                    })
                }}
            />

            <InputField
                type={showPassword ? 'text' : 'password'}
                label='Password'
                placeholder='••••••••'
                className='pt-6 w-full'
                isErrored={false}
                onChange={(e: any) => {
                    setUserFormDetails({
                        ...userFormDetails,
                        password: e.target.value,
                    })
                }}
                leadingIcon={
                    userFormDetails.password !== '' ? (
                        <i
                            className={CN('hover:cursor-pointer', {
                                'ri-eye-off-line': showPassword == false,
                                'ri-eye-line': showPassword == true,
                            })}
                        />
                    ) : undefined
                }
                leadingIconOnClick={() => {
                    setShowPassword(!showPassword)
                }}
            />

            <div className='flex justify-between items-center pt-6'>
                {/*<Checkbox*/}
                {/*    labelText='Remember me'*/}
                {/*    helperText=''*/}
                {/*    checked={rememberMeStatus}*/}
                {/*    onChange={(e: any) => {*/}
                {/*        rememberMeAction(e.target.checked)*/}
                {/*    }}*/}
                {/*/>*/}

                <Button
                    onClick={() => {
                        navigate('/auth/forgot-password')
                    }}
                    contentClassName='!no-underline'
                    appearance='link'>
                    Forgot password?
                </Button>
            </div>

            <Button
                appearance='primary'
                className='mt-6 w-full'
                disabled={!userFormDetails.username || !userFormDetails.password}
                isLoading={logInIsLoading}
                onClick={() => {
                    handleLogInClick()
                }}>
                LOGIN
            </Button>
        </div>
    )
}

LoginFrom.defaultProps = {}

export default LoginFrom
